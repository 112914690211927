import React from "react"
import Helmet from "react-helmet"
import Layout from "../../../components/layout"
import { Container, Row, Col } from "react-bootstrap"
import JumboPhoto from "../../../components/jumbophoto"

export default () => <Layout>
    <Helmet title="Can I keep my car in bankruptcy? | Gina Mcdonald &amp; Associates, L.L.C." defer={false} />

	<JumboPhoto/>

    <Container>
        <Row>
            <Col>
                <h1>Can I keep my car in bankruptcy?</h1>
                <hr/>

                <p className="lead">Birmingham Bankruptcy Attorneys helping you keep your car in Hoover, Anniston, Tuscaloosa and the rest of Alabama</p>

                <p>"If I file Bankruptcy, can I keep my car?" This is a common question of debtors when contemplating a bankruptcy filing. Without a vehicle, how can the debtor get to work, take his children to school, or even think about availing himself of his so-called “fresh start.” The answer to this question depends in large part on the applicable state property exemptions, as well as the fair market value of the debtor’s vehicle and the amount of equity he has in it. (Currently, Alabama’s exemption is $3,000.)</p>

                <p>If the state exemption for vehicles is a dollar amount above the amount of equity the debtor has in the vehicle, then he can claim an exemption and will be able to keep the car. If the debtor has more equity in the car than the allowable exemption amount, then there are limited options. First, the debtor can turn the car over to the trustee, who will sell it and remit the exemption amount to the debtor while distributing the rest to unsecured creditors. If he is able to do so, he may pay the amount by which his equity in the car exceeds the exemption amount and keep the car.</p>

                <p>Bankruptcy exemptions such as the vehicle exemption can be tricky, and you need an experienced bankruptcy attorney to help you navigate the bankruptcy code. The Birmingham bankruptcy attorneys of Gina H. McDonald & Associates, LLC will help you keep as much of your property as possible for your fresh start, and guide you through the process in a caring and understanding manner, outlining your options along the way. Contact us today at 205-982-3325 for a free consultation, and let us put our experience to work for you.</p>
            </Col>
        </Row>
    </Container>
</Layout>